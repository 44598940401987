<template>
  <div class="content block-el">
    <template v-if="fetched">
        <PageHeader :title="`${ticket.subject}`" class="head-title">
        <p class="p-support">
          <span>Ticket #{{ ticket.id | minifyUuid }}</span>
          <span>Aberto {{ ticket.created_at | formatDate24 }}</span>
          <span>Produto {{ ticket.product_name }}</span>
        </p>
        <span class="badge badge-info to-right" v-if="ticket.status == 'open'"
          >{{$t('generic-str.status.lbl-open')}}</span
        >
        <span
          class="badge badge-danger to-right"
          v-else-if="ticket.status == 'closed'"
          >{{$t('generic-str.status.lbl-closed')}}</span
        >
        <span
          class="badge badge-dark to-right"
          v-else-if="ticket.status == 'archived'"
          >{{$t('generic-str.status.lbl-filed')}}</span
        >
        <router-link to="/support"
          style="margin-top:-70px; position: absolute; right: 30px"
          class="btn btn-secondary hide"
        ><i class="fas fa-arrow-left color-white"></i> {{$t('generic-str.return')}}</router-link>
      </PageHeader>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-sm-8">
            <div class="card">
              <div class="card-header" style="border-bottom: 1px solid #fff;">
                <div class="w-100">
                  <h3 class="card-title m-0 to-left">Seus chamados</h3>
                  <div class="to-right opts_card" style="margin-left: 10px;">
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive responsive-table">
                  <table v-if="fetched && messages.length" class="table">
                    <thead>
                      <tr>
                        <th class="th-monitor" scope="col">DATA</th>
                        <th class="th-monitor" scope="col">DEPARTAMENTO</th>
                        <th class="th-monitor" scope="col">ASSUNTO</th>
                        <th class="th-monitor" scope="col">CONTEÚDO</th>
                        <th class="th-monitor" scope="col">QUEM</th>
                        <th class="th-monitor" scope="col">STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="message in messages" :key="message.id">
                        <td class="td-monitor">{{ message.created_at | formatDate }}</td>
                        <td class="td-monitor">
                          <span v-if="message.category">{{ message.category }}</span>
                          <span v-else>Não informado</span>
                        </td>
                        <td class="td-monitor">
                          <span v-if="message.subject">{{ message.subject }}</span>
                          <span v-else>Não informado</span>
                        </td>
                        <td class="td-monitor">
                          <span v-if="message.content">{{ message.content }}</span>
                          <span v-else>Sem conteúdo</span>
                          <p v-if="message.attachment">
                            <span v-for="(message, i) in JSON.parse(message.attachment)" :key="i" style="margin-left: 5px">
                              <a :href="message.url" class="btn btn-default" target="_blank"><i class="fas fa-download color-white"></i> {{message.name}}</a>
                            </span>
                          </p>
                        </td>
                        <td class="td-monitor">
                          <span v-if="message.type != 'staff'">
                            {{ message.user.name }}
                          </span>
                          <span v-if="message.type == 'staff'">
                            {{$t('generic-str.supp')}}
                          </span>
                        </td>
                        <td class="td-monitor">
                          <span class="badge badge-success">
                            Publicado
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else-if="fetched" class="text-center table-placeholder">
                    <i class="fas fa-life-ring font-size-80"></i>
                    <h5 class="card-title m-t-20">{{$t('ticket-component.none')}}</h5>
                    <p>
                      <a
                        href="/support/new-ticket"
                        class="btn btn-success"
                        aria-expanded="false"
                      >
                        {{$t('generic-str.lbl-open-ticket')}}
                      </a>
                    </p>
                  </div>
                  <div class="loading min-h-300" v-else>
                    <LoadingAnim />
                  </div>
                  <br />
                  <pagination :lastPage="pages" @change="fetch" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card" style="padding: 10px;">
              <div class="card-header" style="border-bottom: 1px solid #fff;">
                <div class="w-100">
                  <h3 class="card-title m-0 to-left">Responder</h3>
                </div>
              </div>
              <div class="card-body">
                <div class="ticket-container-response">
                  <form action="#" class="form-horizontal">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group row">
                          <label class="control-label text-left col-12" for="categoty">{{$t('generic-str.department')}}</label>
                          <div class="col-md-12">
                            <select
                              id="categoty"
                              class="form-control"
                              v-model="form.category"
                            >
                              <option selected>{{$t('generic-str.department')}}</option>
                              <option value="support">{{$t('generic-str.supp')}}</option>
                              <option value="billing">{{$t('home.invite-card.select.fin')}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="control-label text-left col-12"
                            >Arquivos:</label
                          >
                          <div v-if="!importing" class="col-md-12">
                            <input
                              ref="uploadedFile"
                              type="file"
                              hidden
                              multiple
                              @change="uploadFile"
                            />
                            <div class="input-group mb-3">
                              <div class="form-control">{{ form.file }}</div>
                              <div class="input-group-append">
                                <button
                                  class="btn btn-add-file"
                                  type="button"
                                  @click="openFileUpload"
                                >
                                <span class="material-symbols-outlined">upload</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div v-else class="col-md-6">
                            <div class="row">
                              <div class="col-12">
                                <small>{{$t('sms.send-msg.tabs.archives.lbl-validating')}}</small>
                              </div>
                            </div>
                            <div class="progress">
                              <div
                                class="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                :style="`width: ${uploadProgress}%;`"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {{ uploadProgress }}%
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="row" v-for="(attachment, i) of form.attachments" :key="i">
                              <div class="col-sm-6">
                                <div class="card">
                                  <div class="row p10">
                                    <div class="col-sm-10" style="padding-top:10px;">
                                      <h6>{{attachment.name}}</h6>
                                    </div>
                                    <div class="col-sm-2">
                                      <button class="btn btn-danger w100" type="button" @click="removeFile(i, attachment.id)">
                                        <i class="fas fa-times color-white"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="control-label text-left col-12"
                            >{{$tc('generic-str.message', 1)}}:</label
                          >
                          <div class="col-md-12">
                            <textarea
                              type="text"
                              v-model="form.message"
                              class="form-control"
                              rows="5"
                            ></textarea>
                          </div>
                        </div>
                        <div class="response-buttons">
                          <button
                            type="button"
                            :class="{
                              'qt-loader qt-loader-mini qt-loader-right': isSending,
                            }"
                            :disabled="isSending"
                            class="btn btn-primary float-right btn-response"
                            @click="send()"
                          >
                            Enviar resposta
                          </button>
                        </div>
                        <div class="response-buttons">
                          <router-link
                            to="/support/new-ticket" class="btn-add-new"
                          >
                            Abrir novo chamado
                          </router-link>
                        </div>
                        <div class="response-buttons">
                          <button
                            v-if="ticket.status == 'open'"
                              type="button"
                              :class="{
                                'qt-loader qt-loader-mini qt-loader-right': isSending,
                              }"
                              :disabled="isSending"
                              class="btn btn-danger float-right"
                              @click="close()"
                            >
                            {{$t('generic-str.close')}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- modal para criar novo item -->
          <b-modal id="responseModal" hide-footer class="modal-lg" size="lg">
            <template #modal-title>
              Respostas
            </template>
            <div style="padding: 20px;">
              <div class="row" >
                <div class="col-sm-12">
                  <br>
                </div>
              </div>
              <button @click="create('blank')" class="hide">{{$t('flows.blank')}}</button>
            </div>
          </b-modal>
        </div>
      </div>
    </template>
    <div
      v-else
      class="static qt-block-ui"
      style="padding: 120px; margin-top: 15px"
    />
    <!-- <div
      v-else
      class="relative qt-block-ui"
      style="padding: 120px; margin-top: 15px"
    /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import TicketService from '@/services/ticket.service';
import PageHeader from '@/components/PageHeader.vue';
import BucketService from '@/services/bucket.service';
// import moment from 'moment';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
  },
  data() {
    return {
      fileImported: {},
      ticket: {},
      messages: [],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: false,
      beginDate: '',
      endDate: '',
      form: {
        message: '',
        created_at: '',
        attachments: [],
      },
      isSending: false,
      pages: 1,
      loading: true,
      exporting: false,
      importing: false,
      uploadProgress: 0,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    removeFile(i, id) {
      BucketService.deleteFile(id).then(
        () => {},
        (error) => {},
      );
      this.form.attachments.splice(i, 1);
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const files = event.target.files;
      const filesAr = [];
      files.forEach((file) => {
        filesAr.push(file);
        formData.append('files[]', file);
      });
      console.log(filesAr);
      // formData.append('files[]', filesAr);
      formData.append('role', 'public');
      formData.append('folder', 'ticket');

      Promise.all([
        BucketService.upload(
          formData,
          true,
          this.callBackProgress,
        ),
      ])
        .then(
          (responses) => {
            console.log(responses[0].data);
            responses[0].data.forEach((file) => {
              this.form.attachments.push(file);
            });
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.importing = false;
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    fetch() {
      this.fetched = false;
      TicketService.getTicket(this.$route.params.id).then(
        (ticket) => {
          this.messages = ticket.messages;
          this.ticket = ticket;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    info() {
      return this.$store.state.account;
    },
    close() {
      this.isSending = true;
      TicketService.closeTicket(this.ticket.id)
        .then(
          () => {
            this.fetch();
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('ticket-component.closed'),
              type: 'success',
            });
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    send() {
      if (this.form.message.length === 0) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('ticket-component.category'),
          type: 'danger',
        });
        return;
      }

      this.isSending = true;
      console.log(this.form);
      TicketService.updateTicket(this.form, this.ticket.id)
        .then(
          (send) => {
            console.log(send);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.msg-sent'),
              type: 'success',
            });
            this.form.message = '';
            this.form.attachments = [];
            this.isSending = false;
            this.fetch();
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
.p-support {
  color: #808080;
  span {
    margin-right: 24px;
  }
}
.card-title {
  font-size: 26px !important;
  color: var(--foreground);
}
.th-monitor {
  width: 18%;
}
.btn-add-file {
  background: #F7F7F7;
}
.btn-add-file span {
  font-size: 20px;
  color: #959595;
}
.btn-response {
  background: #752DE6 !important;
  border: none;
  color: #Fff !important;
}
.response-buttons {
  text-align: center !important;
}
.response-buttons button {
  float: none !important;
  margin-top: 30px;
}
.btn-add-new {
  color: #752DE6;
  font-size: 14px;
  margin-top: 30px;
  display: block;
}
.no-bg {
  background: none !important;
  border: none !important;
  padding: none !important;
  margin: 0 !important;
}
.no-bg:hover {
  background: none !important;
}
</style>
